import { useCallback, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Footer = () => {
    const location = useLocation();
    const navigationItems = useMemo(() => {
        
    return (
        <ul className="list-none flex space-x-1  ml-36">
            <li className="font-sans text-lg subpixel-antialiased py-8">
                    <NavLink className="px-2" activeClassName="text-blue" to='/legal/politica-confidentialitate/mobil'>Politica de confidentialitate</NavLink>
            </li>
            <li className="font-sans text-lg subpixel-antialiased py-8">
                    <NavLink className="px-2" activeClassName="text-blue" to='/legal/termeni-si-conditii/mobil'>Termeni si conditii</NavLink>
            </li>
        </ul>
    )
    }, []);
    const getPositioningClass = useCallback(() => {
        if (location.pathname === '/') return 'absolute bottom-0';

        return 'relative';
    }, [location]);
    return (
        <div className={`h-22 w-full flex z-90 bg-white justify-between ${getPositioningClass()}`}>
            {navigationItems}
            <div className="flex mr-36 items-center">
                <p className=" italic">Powered by <a href="https://digitalmindset.ro" rel="noreferrer" target="_blank" className="text-black font-semibold">Digital Mindset</a></p>
            </div>
        </div>
    )
}

export default Footer;