import React, { useCallback } from 'react';
import {ReactComponent as Logo} from '../../assets/images/logo with white.svg';
import { useHistory } from 'react-router-dom';
import NavigationList from './NavigationList';

const Header = () => {
  const history = useHistory();
  const navigateToHome = useCallback(() => {
    history.push('/');
  }, [history]);
  return (
    <div className="h-24 w-full flex justify-end shadow-md z-90 bg-white absolute">
        <Logo className="absolute left-32 w-44 -top-1.7 cursor-pointer" onClick={navigateToHome}/>
        <div className="mr-32">
          <NavigationList/>
        </div>
    </div>
  )
}


  export default Header;