import React, {lazy, Suspense} from 'react';

import './App.css';
import { 
  Route,
  Switch,
} from 'react-router-dom';
import LoadingScreen from './components/loadingScreen/LoadingScreen';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';

const Home = lazy(() => import('./containers/home/Home'));
const Docs = lazy(() => import('./containers/documentatie/Documentatie'));
const Suport = lazy(() => import('./containers/suport/Suport'));
const Primarie = lazy(() => import('./containers/primarie/Primarie'));
const Notificare = lazy(() => import('./containers/primarie/notificare/Notificare'));
const Legal = lazy(() => import('./containers/legal/Legal'));
const NoMatch = lazy(() => import('./containers/noMatch/NoMatch'));

function App() {
  return (
    <div className="Primaria-Mea-App max-w-ful h-screen relative">
      <Header/>
      <Suspense fallback={<LoadingScreen/>}>
        <Switch>
          <Route path="/docs">
            <Docs/>
          </Route>
          <Route exact path="/primarie/:primarieName">
            <Primarie/>
          </Route>
          <Route exact path="/primarie/:primarieName/notificare/:notificationId">
            <Notificare/>
          </Route>
          <Route path="/suport">
            <Suport/>
          </Route>
          <Route exact path="/legal/:type(politica-confidentialitate|termeni-si-conditii)/:platforma(mobil|mobile|web)">
            <Legal/>
          </Route>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
        <Footer/>
      </Suspense>
    </div>
  );
}

export default App;
