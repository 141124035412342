import React, {useMemo} from 'react';
import { NavLink } from 'react-router-dom';

const NAV_ITEMS = [
    {
        path: '/',
        title: 'Acasa'
    },
    {
        path: '/docs',
        title: 'Documentatie'
    },
    {
        path: '/suport',
        title: 'Suport'
    }
];
const NavigationList = () => {
    // <NavLink to="/about">About</NavLink>

    const NavigationItems = useMemo(() => {
        return NAV_ITEMS.map((item) => {
            return (
                <li key={item.title} className="font-sans text-lg subpixel-antialiased py-8 font-semibold">
                    <NavLink className="px-2" exact activeClassName="text-blue" to={item.path}>{item.title}</NavLink>
                </li>
            )
        })
    }, []); 
    return (
        <ul className="list-none flex space-x-1">
            {NavigationItems}
        </ul>
    )
}

export default NavigationList;